
import { Dropdown } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Show_Banner } from '../../actions/banner.actions'


export const Files = (props) => {
  //const { club, Show_Banner} = props
  const [visibility, setVisibility] = useState(false)

  console.log(props)

  const handleVisibility = () => {
    props.visibility({key: 'Files', value: visibility})
    setVisibility(!visibility)
  }

  useEffect(() => {
    if(visibility){  
      console.log('SHOW FILES')
    }
    return () => {
      console.log('FLUSH FILES')
    }
  }, [visibility])


  if(visibility)
  return (
    <>
      <div className='col-12 my-1'>
        <div className='d-flex flex-row justify-content-between' onClick={handleVisibility}>
        <h4>Files</h4>
          <i className="bi bi-arrows-collapse" />  
        </div>
        <div className='row'>
          <div className='col-12'>
            <div style={{display: 'block', backgroundColor: '#abcdef', width: '100px', height: '250px'}}>

            </div>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Dropdown Button
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <hr />
      </div>
    </>
  )
else 
  return (
    <div className='col-12 my-1' onClick={handleVisibility}>
      <div className='d-flex flex-row justify-content-between' onClick={handleVisibility}>
        <h4>Files</h4>
        <i className="bi bi-arrows-expand" />
      </div>
    <hr />
  </div>
)
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = dispatch => ({
  Show_Banner: (msg, severity) => dispatch(Show_Banner(msg, severity))
})

export default connect(mapStateToProps, mapDispatchToProps)(Files)
