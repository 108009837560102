import React from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

export const GameCards = (props) => {
  let path = useLocation().pathname

  let game = props.game
  let date = new Date(0)
  if(game.playedOn){
    date.setUTCSeconds(game.playedOn._seconds)
  }
  return (
    <div className='card text-center border-dark col-md-12 col-lg-2 m-2 shadow-lg' style={{ padding: '0px'}}>
      <Link to={`${path}/${game.docPath}`} >
        <div className="card-header">
          {game.homeTeam} vs {game.awayTeam}
        </div>
        <div className="card-body">
          <h3>{game.results}</h3>
        </div>
        {game.playedOn ? 
        <div className='card-footer'>
          <small className='disabled'>{date.toLocaleDateString('sv-SE')}</small>
        </div>
        :
        null
        }
      </Link>
    </div>
  )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(GameCards)
