import React, { useState, useEffect } from 'react'
import TeamSelector from './GameControls/TeamSelector'
import PlayerSelector from './GameControls/PlayerSelector'
import ActionSelector from './GameControls/ActionSelector'
import ActionSearch from './GameControls/ActionSearch'
import ActionList from './GameControls/ActionList'
import VideoOffset from './GameControls/VideoOffset'

export default function GameControls({teams, scoutfile = [], setVideoOffset, ...props}) {
  const [SelectedTeam, setSelectedTeam] = useState({teamName: '', teamIdentifier: '[\\*a]'})
  const [SelectedPlayer, setSelectedPlayer] = useState({playerNumber: 0, lastName: '', firstName: ''})
  const [SelectedAction, setSelectedAction] = useState({name: '', id: '#'})
  const [RegEx, setRegEx] = useState('')
  const [List, setList] = useState([])

  useEffect(() => {
    let tempList = []
    for(let row of scoutfile){
      let temp = row.split(';')
      
      if(temp[0].match(RegEx)){
        let obj = {
          action: temp[0],
          time: temp[12],
        }
        tempList.push({...obj})
      }
    }
    console.log(`Actionlist Length: ${tempList.length}`)
    setList(tempList)
    
    //eslint-disable-next-line
  }, [RegEx])

  if(teams){
    const playerList = (SelectedTeam.teamName === teams.homeTeamName ? teams.homeTeam : SelectedTeam.teamName === teams.awayTeamName ? teams.awayTeam : [] )
    
    return (
      <div>
        <table className='table text-center align-middle'>
          <tbody>
            <TeamSelector teams={teams} onChangeHandler={setSelectedTeam} setSelectedPlayer={setSelectedPlayer} />
            <PlayerSelector players={playerList} onChangeHandler={setSelectedPlayer} />
            <ActionSelector onChangeHandler={setSelectedAction} />
            <ActionSearch SelectedTeam={SelectedTeam} SelectedPlayer={SelectedPlayer} SelectedAction={SelectedAction} setRegEx={setRegEx} />
            <VideoOffset setVideoOffset={setVideoOffset} />
            {/*
            <tr>
              <td colSpan='2'>
                <p>{SelectedTeam.teamName} - {SelectedPlayer.lastName} - {SelectedAction.name}</p>
              </td>
            </tr>
            */}
            <ActionList list={List} setVideoPosition={props.setVideoPosition} />
          </tbody>
        </table>
      </div>
    )  
  }
  else {
    return null
  }
}
