import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Show_Banner } from '../../actions/banner.actions'
import { Redirect } from "react-router-dom";
import ClubInfo from './ClubInfo'
import UserManager from './UserManager'
import Users from './Users'
import { Update_Clubs } from '../../actions/clubs.actions';
import Files from './Files';
import Games from './Games';

export const Manager = (props) => {
  const { clubs } = props.clubs
  const { token } = props.user

  const [club, setClub] = useState(undefined)
  const [windowSize, setWindowSize] = useState({width: window.width, height: window.height})
  const [childProp, setChildProp] = useState({})

  useEffect(() => {
    if(clubs.length === 1)
      setClub(clubs[0])
  }, [clubs])

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [windowSize])

  const childPropVisibilityChanged = ({key, value}) => {
    let tempObj = {}
    tempObj[key] = value
    
    setChildProp({...childProp, ...tempObj})

    console.log(`Set ${key} to value ${value}`)
  }

  try {

    const onClickHandler = async ({club, type}) => {
      switch(type){
        case 'CLUB': {
          setClub(club)
          break;
        }
        case 'CREATE_NEW_CLUB': {
          const identifier = prompt('Please specify the identifier, MUST be unique!')
          console.log(identifier)
          if(identifier && identifier.length > 0){
            await fetch('/api/v1/clubs/create', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
              body: JSON.stringify({identifier, user: props.user})
            })
            .then(res => res.json())
            .then(res => res.status ? props.Update_Clubs(res.club) : null)
            .catch(err => console.error(err))
          }
          break;
        }
        default: {
          break;
        }
      }
    }

    const clubRows = clubs.sort((a,b) => a - b).map((c) => {
      return <tr key={c.identifier} style={{backgroundColor: c.documentRef === club?.documentRef ? '#96bfff' : ''}} onClick={() => onClickHandler({type: 'CLUB', club: c})}><td>{c.name || c.identifier}</td></tr>
    })

    return (
      <div className='row' style={{overflowY: 'auto', height: '800px'}}>
        <div className='col-sm-12 col-md-2' style={{padding: '0px'}}>
          <table className='table table-hover text-center'>
            <thead><tr><td><h5>Clubs</h5></td></tr></thead>
            <tbody>
              {clubRows}
            </tbody>
            <tfoot>
              <tr><td><button className='btn btn-sm btn-secondary' onClick={() =>  onClickHandler({type: 'CREATE_NEW_CLUB'})}>Create</button></td></tr>
            </tfoot>
          </table>
        </div>


        {club ? 
        <div className='col-sm-12 col-md-10 shadow-lg'>
          <div className='row'>
            <ClubInfo club={club} />
            <Users club={club} />
            <UserManager club={club} clubs={clubs} />
            <Games club={club}/>
            {<Files club={club} visibility={childPropVisibilityChanged} />}
          </div>
        </div>
        : 
        <div className='col-10 shadow-lg'>
          <div className='row'>
            <h5>Please select a club in the menu on the left</h5>
          </div>
        </div>}
      </div>
    )
  }
  catch(e) {
    props.Show_Banner('An error occured during loading, please try again. Error: (Manager.jsx did not get any clubs.)', 'danger')
    return <Redirect to='/' />
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  clubs: state.clubs
})

const mapDispatchToProps = dispatch => ({
  Show_Banner: (msg, severity) => dispatch(Show_Banner(msg, severity)),
  Update_Clubs: (club) => dispatch(Update_Clubs({club}))
})


export default connect(mapStateToProps, mapDispatchToProps)(Manager)
