import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Hide_Banner } from '../actions/banner.actions'
import { Authenticate_User } from '../actions/user.actions'

import {auth} from '../../firebase'

export const SignIn = (props) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const signInWithEmailAndPasswordHandler = (email, password) => {
    setError(null)
    auth.signInWithEmailAndPassword(email, password).catch(error => {
      setError({ show: true, msg: error.message});
      console.error(error);
    });

  };  
  
  const spinner = () => {
    return <div className='d-flex justify-content-center'><div className="spinner-border m-3" style={{width: '3rem', height: '3rem'}} role="status" /></div>
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()
    signInWithEmailAndPasswordHandler(email, password)

  }

  const form = () => {
    return <React.Fragment>
      <form onSubmit={onSubmitHandler} >
        <label className='col-form-label'>Email</label>
        <input className='form-control' type='username' required placeholder='' style={{ textAlign: 'center' }} value={email} onChange={(e) => setEmail(e.target.value)} />
        <label className='col-form-label'>Password</label>
        <input className='form-control' type='password' required placeholder='' style={{ textAlign: 'center' }} value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type='submit' className='btn btn-primary m-3' >Submit</button>
      </form>
      <hr />
      <p style={{display: error?.show ? 'block' : 'none'}} className='text-danger'>Please try again or reach out to your Technical Director or <a href="mailto:sl@sweprod.com">Sebastian Lilja</a></p>
      <p style={{display: error?.show ? 'block' : 'none'}} className='text-danger'>{error?.msg}</p>
    </React.Fragment>

  }

  return (
    <>
      {props.user.email !== undefined && props.user.email !== '' && props.user.email.length > 3 ? <Redirect to='/' /> : null}

      <div className='row m-3'>
        <div className='col-3' />
        <div className='col-md-6 col-sm-12' align='center'>
          <h5>Use the Email you provided.</h5>
        </div>
        <div className='col-3' />
      </div>
      <div className='row m-3' >
        <div className='col-3'></div>
        <div className='col-md-6 col-sm-12' align='center'>
          {props.user.isLoading ? spinner() : form()}
        </div>
        <div className='col-3'></div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  Authenticate: ({email, token}) => dispatch(Authenticate_User({email, token})),
  Hide_Banner: () => dispatch(Hide_Banner())
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
