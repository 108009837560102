const initialState = {
  videoContainerSize: {width: 190, height: '720'}
}

// eslint-disable-next-line
export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'INSERT_ACTION_HERE':
    return {
      ...state
    }
  default:
    return state
  }
}
