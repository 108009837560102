import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux'

export const AddSingleUser = (props) => {
  const { showing } = props
  const handleClose = () => {
    setName('')
    setEmail('')
    setPassword('')

    props.close()
  }
  const handleShow  = () => props.show()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onClickHandler = () => {
    props.onAddHandler({type: 'AddUser', data: {name, email, password}})
    handleClose()
  }

  return (
    <>
      <Button variant="primary" className='my-2 mx-2' onClick={handleShow}>
        Add User
      </Button>

      <Modal show={showing} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className='mx-auto'>
            <tbody>
              <tr><td>Name:</td><td><input type='text' value={name} onChange={(e) => setName(e.target.value)}/></td></tr>
              <tr><td>Email:</td><td><input type='text' value={email} onChange={(e) => setEmail(e.target.value)}/></td></tr>
              <tr><td>Password:</td><td><input type='text' value={password} onChange={(e) => setPassword(e.target.value)}/></td></tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={onClickHandler}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSingleUser)
