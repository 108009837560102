import React from 'react'
import { connect } from 'react-redux'
import { Hide_Banner } from '../actions/banner.actions'

const Banner = (props) => {
  const { banner } = props
  if(banner !== undefined) {
    if(banner.active){
      const alertClassification = 'alert ' + banner.class
      console.log('Rendering Banner');
      if(banner.msg.includes('#link'))
        return (
          <div className={alertClassification} role="alert" align="center" style={{marginBottom: '0px'}}>
            <label className='align-middle'>
              {banner.msg.replace('#link', '')}
              <a href={banner.link.link} target="_blank" rel="noopener noreferrer">Here</a>
              <button type='button' className='btn-close float-right' style={{marginLeft: '10px'}} onClick={() => props.Hide_Banner()} />
            </label>
          </div>
          )    
      else {
        return (
          <div className={alertClassification} role="alert" align="center" style={{marginBottom: '0px'}}>
            <label className='align-middle'>
              {banner.msg}
              <button type='button' className='btn-close float-right' style={{marginLeft: '10px'}} onClick={() => props.Hide_Banner()} />
            </label>
          </div>
          )    
      }
    }
  }
  return null
}

const mapStateToProps = (state) => ({
  banner: state.banner
})

const mapDispatchToProps = dispatch => ({
  Hide_Banner: () => dispatch(Hide_Banner()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Banner)
