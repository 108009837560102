
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Show_Banner } from '../../actions/banner.actions'
import SpinnerSmall from '../Spinner/SpinnerSmall'


export const Games = (props) => {
  const { club, Show_Banner} = props
  const { token } = props.user

  const [visibility, setVisibility] = useState(false)
  const [games, setGames] = useState([])
  const [Loading, setLoading] = useState(false)

  const handleVisibility = () => {
    setVisibility(!visibility)
  }

  useEffect(() => {
    if(visibility){
      setLoading(true)
      fetch('/api/v1/games/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        body: JSON.stringify({documentRef: club.documentRef})
      })
      .then(res => res.json())
      .then(res => res.status ? setGames(res.games) : Show_Banner('Seems like the server are experiencing a bit of trouble', 'danger'))
      .catch(e => Show_Banner('Seems like the server are experiencing a bit of trouble', 'danger'))
      .finally(() => setLoading(false))
    }
    return () => {
      setGames([])
    }
    // eslint-disable-next-line
  }, [club, visibility])


  const table = () => {
    return <div className='row'>
      <div className='col-12'>
        <table className='table table-bordered table-striped'>
          <thead>
            <tr className='table-dark'>
              <td>Played on</td>
              <td>Teams</td>
              <td>Created on</td>
            </tr>
          </thead>
          <tbody>
            {
              games.map((game) => {
                return <tr key={game.docRef}>
                  <td>{new Date(game.playedOn._seconds * 1000).toLocaleDateString()}</td>
                  <td>{`${game.homeTeam} - ${game.awayTeam}`}</td>
                  <td>{new Date(game.createdOn._seconds * 1000).toLocaleString()}</td>

                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  }

  if(visibility)
    return (
      <>
        
        <div className='col-12 my-1'>
          <div className='d-flex flex-row justify-content-between' onClick={handleVisibility}>
          <h4>Games</h4>
            <i className="bi bi-arrows-collapse" />  
          </div>
          <div className='row'>
            <div className='col-12'>
            </div>
          </div>
          {Loading ? <SpinnerSmall /> : table()}
          <hr />
        </div>
      </>
    )
  else 
    return (
      <div className='col-12 my-1' onClick={handleVisibility}>
        <div className='d-flex flex-row justify-content-between' onClick={handleVisibility}>
          <h4>Games</h4>
          <i className="bi bi-arrows-expand" />
        </div>
      <hr />
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  Show_Banner: (msg, severity) => dispatch(Show_Banner(msg, severity))
})

export default connect(mapStateToProps, mapDispatchToProps)(Games)
