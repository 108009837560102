import React, { useContext, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import Header from './components/Header';
import Clubs from './components/Clubs';
import SignIn from './components/SignIn';
import Games from './components/Games';
import Game from './components/Game';
import './styles.css'
import { UserContext } from '../providers/UserProvider';
import { Set_User, Set_User_Token } from './actions/user.actions';
import Manager from './components/Manager/Manager';
import { getUserToken } from '../firebase';

export const App = (props) => {
  const userContext = useContext(UserContext)

  //const { user } = props

  useEffect(() => {
    const token = getUserToken()

    if (token) {
      props.Set_User_Token(token)

      fetch(`/api/v1/users/${userContext.uid}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(res => res.json())
        .then(res => props.Set_User(res.user))
        .catch(err => console.error(err))
    }
    // eslint-disable-next-line
  }, [userContext])

  return (
    <div className='App container-fluid'>
      <Router>
        <Header />
        {props.user.email ? <Redirect to='/' /> : null}
        {props.user.email === undefined || props.user.email === '' || props.user.email === null ? <Redirect to='/signin' /> : null}
        <Switch>

          <Route path='/signin'>
            <SignIn />
          </Route>

          <Route path='/c/:clubID/:gameID' component={(match) => <Game routeObject={match} />} />
          <Route path='/c/:clubID' component={(match) => <Games routeObject={match} />} />
          <Route path='/c'>
            <Redirect from='/c/' to='/' />
          </Route>

          <Route path='/manage' component={(match) => <Manager match={match} />} />

          <Route path='/admin' component={() => <div>Admin</div>} />


          <Route path='/'>
            <Clubs />
          </Route>

        </Switch>
      </Router>
    </div>
  )

}

const mapStateToProps = (state) => ({
  error: state.error,
  user: state.user,
  clubs: state.clubs
})


const mapDispatchToProps = dispatch => ({
  Set_User: (user) => dispatch(Set_User(user)),
  Set_User_Token: (token) => dispatch(Set_User_Token({ token: token }))
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
