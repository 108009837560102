import { combineReducers } from "redux";

import { appReducer } from './app.reducer'
import { bannerReducer } from "./banner.reducer";
import { userReducer } from "./user.reducer";
import { clubsReducer } from "./clubs.reducer";
import { gamesReducer } from "./games.reducer";
import { settingsReducer } from "./settings.reducer";

export default combineReducers({
  app: appReducer,
  banner: bannerReducer,
  user: userReducer,
  clubs: clubsReducer,
  games: gamesReducer,
  settings: settingsReducer
})