import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Show_Banner } from '../actions/banner.actions'
import GameControls from './GameControls'
import Video from './VideoController/Video'


export const Game = (props) => {
  const { clubID, gameID } = props.routeObject.match.params
  const {token} = props.user

  const [ScoutFile, setScoutFile] = useState(undefined)
  const [Teams, setTeams] = useState(undefined)
  const [videoPosition, setVideoPosition] = useState(0)
  const [videoOffset, setVideoOffset] = useState(0)

  let game, club, clubIdentifier;
  let firstTimeUser = Cookies.get('first-time-user')

  if(!firstTimeUser){
    Cookies.set('first-time-user', true)
  }

  const fetchScoutFile = () => {
    fetch(`/api/v1/scout/${clubIdentifier}/${gameID}`, {
      headers: {'Authorization': `Bearer ${token}`}
    })
    .then(res => res.json())
    .then(res => res.status ? 
      setProps(res)
     : null)
    console.log('Fetching Scoutfiles')
  }
  const setProps = (response) => {
    setTeams(response.teams)
    setScoutFile(response.actionlist)
  }

  useEffect(() => {
    if(clubIdentifier && gameID)
      fetchScoutFile()
    return () => {
      console.log('Cleaning up')
    }
    //eslint-disable-next-line
  }, [clubIdentifier, gameID])

  try {
    game = props.games.games.find(obj  => obj.docPath     === gameID)
    club = props.clubs.clubs.find(club => club.identifier === clubID)
    clubIdentifier = club.documentRef
  }
  catch (e) {
    props.Show_Banner('Something went wrong :/ please sign in again', 'danger')
    return <Redirect to='/signin'/>
  }


  if(game === undefined){
    props.Show_Banner('Something went wrong :/ please sign in again', 'danger')
    return <Redirect to='/signin'/>
  }
  else {
    return (
      <div className='row'>
        <div className='col-sm-12 col-md-9'>
          <Video videoURL={game.videoUrl} videoPosition={parseInt(videoPosition)} videoOffset={videoOffset} />
        </div>
        <div className='col-sm-12 col-md-3' style={{display: 'block', border: '0 0 1 0 solid blacks'}}>
          <div className='row justify-content-center'>
            <GameControls teams={Teams} scoutfile={ScoutFile} setVideoPosition={setVideoPosition} setVideoOffset={setVideoOffset} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  clubs: state.clubs,
  games: state.games,
  settings: state.settings,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  Show_Banner: (msg, severity) => dispatch(Show_Banner(msg, severity))
})

export default connect(mapStateToProps, mapDispatchToProps)(Game)
