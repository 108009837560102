import { signOut } from "../../firebase"

export const GET_USER = 'GET_USER'
export const SET_USER = 'SET_USER'
export const SET_USER_TOKEN = 'SET_USER_TOKEN'
export const RESET_USER = 'RESET_USER'
export const ERROR_FETCHING_USER = 'ERROR_FETCHING_USER'

/**
 * 
 * @deprecated
 *
 */

export const Authenticate_User = ({email, token}) => {
  return dispatch => {
    (dispatch({type: GET_USER}))
    fetch('/api/v1/users/authenticate',{
      method: 'POST', 
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({email: email, token: token})
    })
    .then(res => res.json())
    .then(res => res.status ? dispatch({type: SET_USER, payload: {email: res.email, clubs: res.userHasAccessTo, token: res.token, roles: res.roles, document_reference: res.document_reference}}) : dispatch({type: ERROR_FETCHING_USER}) )
    .catch(err => err)
  }
}

export const Set_User = (user) => {
  return dispatch => {
    dispatch({type: SET_USER, payload: {user}})
  }
}

export const Reset_User = () => {
  return dispatch => {
    signOut()
    dispatch({type: RESET_USER})
  }
}

export const Set_User_Token = ({token}) => {
  return dispatch => {
    dispatch({type: SET_USER_TOKEN, payload: {token}})
  }
}