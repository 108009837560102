import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Get_Games } from '../actions/games.actions';
import { GameCards } from './CardTypes/GameCards';
import { Show_Banner } from '../actions/banner.actions';
import { Set_Active_Club } from '../actions/clubs.actions';

export const Games = (props) => {
  const {token} = props.user

  const [searchBar, setSearchBar] = useState('')

  let pathname = props.routeObject.match.params.clubID

  let games = props.games.games

  const getGamesHandler = () => {
    props.Get_Games(club.documentRef, `Bearer: ${token}`, club.name)
  }

  useEffect(() => {
    if(props.clubs.clubs !== undefined){
      let club = props.clubs.clubs.filter(club => club.identifier === pathname)[0]

      if(club){
        /**
         * @todo Use the props.clubs.noNeedToFetchAgain!!!
         */
        if(!props.games.noNeedToFetchAgain || props.games.clubName !== pathname){
          getGamesHandler()
          console.log('Fetching games')
        }
      }
    }
    // eslint-disable-next-line
  }, [pathname, props.clubs.clubs])

  const club = props.clubs.clubs.filter(club => club.identifier === pathname)[0]

  games = games.filter(
    game => game.awayTeam.toLocaleLowerCase().includes(searchBar.toLocaleLowerCase()) || 
    game.homeTeam.toLocaleLowerCase().includes(searchBar.toLocaleLowerCase()) ||
    `${game.homeTeam.toLocaleLowerCase()} vs ${game.awayTeam.toLocaleLowerCase()}`.includes(searchBar.toLocaleLowerCase())
    
    )

  const Cards = games.map((game, id) => {
    return <GameCards key={id + '_' + game.homeTeam + '-' + game.awayTeam} game={game} />
  })

  //const Table = games.map((games, nanoid()))

  const spinner = () => {
    return <div className='d-flex justify-content-center'><div className="spinner-border m-3" style={{width: '3rem', height: '3rem'}} role="status" /></div>
  }

  // eslint-disable-next-line
  const filter = () => {
    return <button className='btn m-2' style={{width: '55px', heigth: '55px'}}>
      <i className="bi bi-filter justify-content-sm-center justify-content-md-left " style={{fontSize: '2em'}}/>
    </button>
  }

  console.log('Rendering Games');
  return (
    <>
      <div className='row justify-content-center align-items-center'>
        <div className='col-sm-4 col-md-1 justify-content-center align-items-center' >
          <button className='btn mx-auto' onClick={() => getGamesHandler()}><i className="bi bi-arrow-repeat" style={{fontSize: '1.5em'}} /></button>
        </div>
        <div className='col-sm-4 col-md-4'>
          <input type='text' className='form-control' placeholder='Search' onChange={(e) => setSearchBar(e.target.value)} value={searchBar}/>
        </div>
      </div>
      <div className='row justify-content-md-left justify-content-sm-center'>
        {props.games.isLoading ? spinner() : Cards.length > 0 ? Cards : <h3 className='text-center my-3'>Could not find any games</h3>}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  clubs: state.clubs,
  games: state.games,
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  Get_Games: (documentRef, token, clubName) => dispatch(Get_Games(documentRef, token, clubName)),
  Show_Banner: (msg, severity) => dispatch(Show_Banner(msg, severity)),
  Set_Active_Club: (clubIdentifer) => dispatch(Set_Active_Club({club: clubIdentifer}))
})

export default connect(mapStateToProps, mapDispatchToProps)(Games)
