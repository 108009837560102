import React from 'react'
import App from './App/App'
import UserProvider from './providers/UserProvider'

export default function Application() {
  return (
    <UserProvider>
      <App />
    </UserProvider>
  )
}
