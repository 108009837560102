import { Show_Banner } from "./banner.actions"

export const STARTED_LOADING_GAMES = 'STARTED_LOADING_GAMES'
export const SET_GAMES = 'SET_GAMES'

export const Get_Games = (documentRef, token, clubName) => {
  return dispatch => {
    (dispatch({type: STARTED_LOADING_GAMES}))
    
    fetch('/api/v1/games', {
      method: 'POST', 
      headers: {'Content-Type': 'application/json', 'Authorization': token},
      body: JSON.stringify({documentRef: documentRef})
    })
    .then(res => res.json())
    .then(res => res.status ? dispatch({type: SET_GAMES, payload: {games: res.games, clubName}}) : Show_Banner("Could not fetch games", 'danger'))
    .catch(err => Show_Banner('Are you offline?','danger'))
  }
}