import React from 'react'

export default function ActionSearch({SelectedTeam, SelectedPlayer, SelectedAction, setRegEx, ...props}) {
  const RegExBuilder = () => {
    let regexString = ""
    
    regexString += `(${SelectedTeam.teamIdentifier})`

    let playerNumber = SelectedPlayer.playerNumber < 10 ? `0${SelectedPlayer.playerNumber}` : SelectedPlayer.playerNumber
    if(playerNumber === '00' || playerNumber === '0') playerNumber = '[0-9]{2,2}'
    regexString += `(${playerNumber})`

    regexString += `(${SelectedAction.id})`

    let regexo = new RegExp(regexString)
    console.log(regexo)
    return regexo
  }
  
  return (
    <tr>
      <td colSpan='2'>
        <button className='btn btn-primary' onClick={() => setRegEx(RegExBuilder())}>
          Search
        </button>
      </td>
    </tr>
  )
}
