/*
import React, { Component, createContext } from "react";
import { auth } from "../firebase";

export const UserContext = createContext({ userFromUserProvider: null });
class UserProvider extends Component {
  state = {
    userFromUserProvider: null
  };

  componentDidMount = () => {
    auth.onAuthStateChanged(user => {
      if(user){
        this.setState({ userFromUserProvider: user});
      }
    });
    
  };
  render() {
    return (
      <UserContext.Provider value={this.state.userFromUserProvider}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;
*/
import React, { createContext, useEffect, useState } from 'react'
import { auth } from "../firebase";

export const UserContext = createContext({ userFromUserProvider: null })

export default function UserProvider(props) {
  const [userFromUserProvider, setUserFromUserProvider] = useState(null)
  useEffect(() => {
    auth.onAuthStateChanged(user => {
      console.log('Authstate changed')
      
      if(user){
        setUserFromUserProvider(user)
      }
    });
    return () => {
      setUserFromUserProvider(null)
    }
  }, [])

  return (
    <UserContext.Provider value={userFromUserProvider}>
      {props.children}
    </UserContext.Provider>
  )
}
