import { Show_Banner } from "./banner.actions"

export const STARTED_LOADING_CLUBS = 'STARTED_LOADING_CLUBS'
export const SET_CLUBS = 'SET_CLUBS'
export const SET_ACTIVE_CLUB = 'SET_ACTIVE_CLUB'
export const UPDATE_CLUBS = 'UPDATE_CLUBS'
export const UPDATE_CLUB_PROPERTY = 'UPDATE_CLUB_PROPERTY'

export const Get_Clubs = ({clubs = [], token, user}) => {
  return dispatch => {
    (dispatch({type: STARTED_LOADING_CLUBS}))
    fetch('/api/v1/clubs/', {
      method: 'POST', 
      headers: {'Content-Type': 'application/json', 'Authorization': token},
      body: JSON.stringify({clubs: clubs, user: user})
    })
    .then(res => res.json())
    .then(res => res.status ? dispatch({type: SET_CLUBS, payload: {clubs: res.clubs}}) : Show_Banner("Could not fetch clubs", 'danger'))
    .catch(err => console.error(err))
  }
}

export const Update_Clubs = ({club}) => {
  return dispatch => {
    dispatch({type: UPDATE_CLUBS, payload: {club}})
  }
}

export const Update_Club_Property = ({clubID, data}) => {
  console.log(clubID, data)
  return dispatch => {
    dispatch({type: UPDATE_CLUB_PROPERTY, payload: {clubID, property: {data}}})
  }
}

export const Set_Active_Club = ({club = ''}) => {
  return dispatch => {
    dispatch({type: SET_ACTIVE_CLUB, payload: {club}})
  }
}