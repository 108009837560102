import { APP_MOUNTED } from "../actions/app.actions"

const initialState = {
  appMounted: false,
}

// eslint-disable-next-line
export const appReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'INSERT_ACTION_HERE':
    return {
      ...state
    }
    case APP_MOUNTED:
      return {
        ...state,
        appMounted: true
      }
  
  default:
    return state
  }
}
