import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Get_Clubs } from '../actions/clubs.actions';
import ClubCards from './CardTypes/ClubCards';


export const Clubs = (props) => {
  const { clubs } = props.user
  const { token } = props.user

  useEffect(() => {
    if(props.user){
      if(props.user.clubs){
        if(props.user.clubs !== [] || props.clubs.clubs !== []){
          if(props.user.clubs.length > 0 && !props.clubs.noNeedToFetchAgain){
            props.Get_Clubs({clubs: props.user.clubs, token: `Bearer: ${token}`, user: props.user})
          }
        }  
      }
    }
    // eslint-disable-next-line
  }, [clubs])

  const refreshClickHandler = () => {
    props.Get_Clubs({clubs: props.user.clubs, token: `Bearer: ${token}`, user: props.user})
  }

  let clubsSortedList = props.clubs.clubs.sort((a, b) => {
    let aName = a.name.toUpperCase()
    let bName = b.name.toUpperCase()

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
  
    // names must be equal
    return 0;
  
  })

  const Cards = clubsSortedList.map((club, id) => {
    return <ClubCards club={club} key={id} />
  })

  const spinner = () => {
    return <div className='d-flex justify-content-center '><div className="spinner-border m-3" style={{width: '3rem', height: '3rem'}} role="status" /></div>
  }

  console.log('Rendering Clubs');
  
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-sm-12 col-md-1'>
          <button className='btn' onClick={refreshClickHandler}><i className="bi bi-arrow-repeat" style={{fontSize: '1.5em'}} /></button>
        </div>
      </div>
      <div className='row justify-content-center'>
        {props.clubs.isLoading ? spinner() : Cards}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  clubs: state.clubs
})

const mapDispatchToProps = dispatch => ({
  Get_Clubs: ({clubs, token, user}) => dispatch(Get_Clubs({clubs, token, user})),
})

export default connect(mapStateToProps, mapDispatchToProps)(Clubs)
