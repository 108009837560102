import { SET_ACTIVE_CLUB, SET_CLUBS, STARTED_LOADING_CLUBS, UPDATE_CLUBS, UPDATE_CLUB_PROPERTY } from "../actions/clubs.actions"

const initialState = {
  clubs: [],
  isLoading: undefined,
  noNeedToFetchAgain: undefined,
  activeClub: undefined
}

export const clubsReducer = (state = initialState, action) => {
  switch (action.type) {
  
    case 'INSERT_ACTION_HERE':
    return {
      ...state
    }
  case SET_CLUBS:
    return {
      ...state,
      clubs: [...action.payload.clubs],
      isLoading: false,
      noNeedToFetchAgain: true
    }
  case STARTED_LOADING_CLUBS:
    return {
      ...state,
      isLoading: true
    }
  case SET_ACTIVE_CLUB: {
    return {
      ...state,
      activeClub: action.payload.club,
    }
  }
  case UPDATE_CLUBS: {
    return {
      ...state,
      clubs: [...state.clubs, action.payload.club]
    }
  }
  case UPDATE_CLUB_PROPERTY: {
    return state
  }
  default:
    return state
  }
}
