import React, { useEffect, useState } from 'react'

export default function Video({videoURL = '', videoPosition, videoOffset, ...props}) {
  const [overlay, setOverlay] = useState(false)
  const videoID = 'videoContainer'

  useEffect(() => {
    if(videoPosition){
      let video = document.getElementById(videoID)
      console.log(`${videoPosition} + ${videoOffset} = ${videoPosition + videoOffset} type: ${typeof (videoPosition + videoOffset)}`)
      video.currentTime = videoPosition + videoOffset
    }
  }, [videoPosition, videoOffset])
//eslint-disable-next-line
  const onClickHandler = ({type, value}) => {
    switch(type){
      case 'TOGGLE_OVERLAY': {

        setOverlay(!overlay)
        break;
      }
      default: {
        break;
      }
    }
  }

  return (
    <div style={{width: '100%'}}>
      <video id={`${videoID}`} width='100%' controls muted style={{position: 'relative'}}>
        <source src={videoURL} />
      </video>
    </div>
  )
}
