import { GET_USER, RESET_USER, SET_USER, ERROR_FETCHING_USER, SET_USER_TOKEN} from "../actions/user.actions"

const initialState = {
  name: undefined, 
  email: undefined, 
  role: undefined,
  isLoading: undefined,
  token: undefined
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
  
    case 'INSERT_ACTION_HERE':
    return {
      ...state
    }
  case SET_USER:
    return {
      ...state,
      ...action.payload.user
    }
  case SET_USER_TOKEN:
    return {
      ...state,
      token: action.payload.token
    }
  case RESET_USER:
    return {
      ...state,
      ...initialState
    }
  case GET_USER:
    return {
      ...state,
      isLoading: true
    }
  case ERROR_FETCHING_USER: {
    return {
      ...state,
      isLoading: false
    }
  }
  default:
    return state
  }
}
