import React from 'react'
import { useState } from 'react'

export default function ActionList({ list, ...props}) {
  const [key, setKey] = useState('')

  const onClickHandler = (key, listItem) => {
    props.setVideoPosition(listItem.time)
    setKey(key)
  }

  const listBuilder = list.map((listItem, id) => {

    let tempKey = id + listItem.action
    return <tr key={tempKey} style={tempKey === key ? {backgroundColor: '#afafff'} : {}} onClick={(e) => onClickHandler(tempKey, listItem)}><td>{listItem.action}</td></tr>
  })

  return (
    <tr>
      <td colSpan='2'>
        <table className='table table-sm' >
          <thead>
            <tr>
              <th>Actions</th>
            </tr>
          </thead>
          <div style={{overflow: "auto", height: "330px", width: "100%"}}>
            <table className="table" width="100%">
              <tbody>
                {listBuilder}
              </tbody>
            </table>
          </div>
        </table>
      </td>
    </tr>
  )
}
