import React, { useState } from 'react'

export default function VideoOffset({setVideoOffset, ...props}) {
  const [selectedVideoOffset, setSelectedVideoOffset] = useState(0)

  const onClickHandler = (value) => {
    setSelectedVideoOffset(value)
    setVideoOffset(value)
  }

  const buttonList = [-5, -2, 0, +2, +5]
  const buttons = buttonList.map((val, id) => {
    return <button key={`video-offset-id-${id}`} className={'btn mx-1 col-2 ' + (val === selectedVideoOffset ? 'btn-primary' : 'btn-secondary')} onClick={() => onClickHandler(parseInt(val))}>{val}</button>
  })

  return (
    <>
      <tr>
        <td colSpan='2' className='col-12'>Offset:</td>
      </tr>
      <tr>
        <td colSpan='2' className='col-12'>
          {buttons}
        </td>
      </tr>
    </>
  )
}
