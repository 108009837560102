import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { AddBatchUsers } from './AddBatchUsers'
import { AddSingleUser } from './AddSingleUser'
import { Show_Banner } from '../../actions/banner.actions'
import { sendResetPWEmail } from '../../../firebase'


export const UserManager = (props) => {
  const { clubs, club } = props
  const { token } = props.user
  const [visibility, setVisibility] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [showingAddBatchUsers, SetShowingAddBatchUsers] = useState(false)
  const [showingAddSingleUser, SetShowingAddSingleUser] = useState(false)
  
  const handleVisibility = () => {
    setVisibility(!visibility)
  }


  useEffect(() => {
    if(visibility){
      fetch('/api/v1/users/get-all-users', {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        method: 'POST',
        body: JSON.stringify({clubs})
      })
      .then(res => res.json())
      .then(res => res.status ? setAllUsers(res.users) : null)
      .catch(err => props.Show_Banner('Could not reach server. Please reload and typ again', 'danger'))
  
    }
    return () => {
      setAllUsers([])
      console.log('FLUSHING ALL USERS')
    }
    // eslint-disable-next-line
  }, [visibility, token])

  const changePropertiesInUserArray = ({user, properties}) => {
    let _temp = [...allUsers]
    let index = _temp.findIndex(u => u.uid === user.uid)
    _temp[index] = {..._temp[index], ...properties}

    setAllUsers(_temp)
  }


  const onClickHandler = ({type, data}) => {
    switch (type){
      case 'Enable/Disable': {
        fetch(`/api/v1/users/${data.user.uid}`, {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
          body: JSON.stringify({properties: {disabled: !data.user.disabled}})
        })
        .then(res => res.json())
        .then(res => res.status ? changePropertiesInUserArray({user: data.user, properties: res.properties}) : null)
        .catch(err => console.error(err))
        break;
      }
      case 'Delete': {
        break;
      }
      case 'ResetPassword': {
        sendResetPWEmail(data.user.email)
        
        fetch(`/api/v1/users/reset-password/${data.user.email}`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        })
        .then(res => res.json())
        .then(res => res.status ? props.Show_Banner(`Password reset for ${data.user.name} was sent to ${data.user.email}. Click #link `, 'success', {link: res.link}) : props.Show_Banner('An error occured', 'danger'))
        .catch(err => props.Show_Banner(err.Message, 'danger'))
        
        break;
      }
      case 'AddUser': {
        let temp = {...data}
        temp.club = club.documentRef

        fetch(`/api/v1/users/`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
          body: JSON.stringify({data: temp})
        })
        break;
      }
      default: {
        break;
      }
    }
  }

  const table = () => {
    return <table className='table table-sm table-bordered table-hover'>
      <thead>
        <tr>
          <td className=''>Name</td>
          <td className=''>Email</td>
          <td className=''>UID</td>
          <td className='text-center'>Status</td>
          <td className='text-center'>Pause</td>
          <td className='text-center'>Reset PW</td>
          <td className='text-center'>Delete</td>
        </tr>
      </thead>
      <tbody>
        {allUsers.map(user => {
          return <tr className={user.disabled ? 'table-secondary' : ''} key={user.uid} >
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>{user.uid}</td>
            <td className='text-center'>{user.disabled ? 'Disabled' : 'Enabled'}</td>
            <td className='text-center'><button className='btn btn-warning btn-sm' onClick={() => onClickHandler({type: 'Enable/Disable', data: {user}})}>X</button></td>
            <td className='text-center'><button className='btn btn-secondary btn-sm' onClick={() => onClickHandler({type: 'ResetPassword', data: {user}})}>Reset</button></td>
            <td className='text-center'><button className='btn btn-danger btn-sm' onClick={() => onClickHandler({type: 'DeleteUser', data: {user}})}>Delete</button></td>
          </tr>
        })}
      </tbody>
    </table>
  }


  if(visibility)
    return (
      <div className='col-12 my-1'>
        <div className='d-flex flex-row justify-content-between' onClick={handleVisibility}>
        <h4>Manage users global - <small className="text-danger"> use with caution!</small></h4>
          <i className="bi bi-arrows-collapse" />
        </div>
        <div className='row'>
          <div className='col-12'>
          <AddBatchUsers close={() => SetShowingAddBatchUsers(false)} show={() => SetShowingAddBatchUsers(true)} showing={showingAddBatchUsers}/>
          <AddSingleUser close={() => SetShowingAddSingleUser(false)} show={() => SetShowingAddSingleUser(true)} showing={showingAddSingleUser} onAddHandler={onClickHandler}/>

          {table()}
          </div>
        </div>
        <hr />
      </div>
    )
  else 
    return (
      <div className='col-12 my-1' onClick={handleVisibility}>
          <div className='d-flex flex-row justify-content-between' onClick={handleVisibility}>
          <h4>Manage users global - <small className="text-danger"> use with caution!</small></h4>
          <i className="bi bi-arrows-expand" />
        </div>
        <hr />
      </div>
    )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  Show_Banner: (msg, severity, link) => dispatch(Show_Banner(msg, severity, link))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserManager)
