export const SHOW_BANNER = 'SHOW_BANNER'
export const HIDE_BANNER = 'HIDE_BANNER'

export const Show_Banner = (msg, severity, link) => {
  return dispatch => {
    dispatch({type: SHOW_BANNER, payload: { severity, msg, link}})
  }
}
export const Hide_Banner = () => {
  return dispatch => {
    dispatch({type: HIDE_BANNER})
  }
}