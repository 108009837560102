import React from 'react'

export default function TeamSelector({teams, ...props}) {


  const provideTeamInfo = (teamName) => {
    props.setSelectedPlayer({playerNumber: 0, lastName: '', firstName: ''})
    let teamObject = {
      teamName: teamName ,
      teamIdentifier: teamName === teams.homeTeamName ? '\\*' : teamName === teams.awayTeamName ? 'a' : '[\\*a]'
    }
    return teamObject
  }

  return (
    <tr>
      <td className='col-4'>Teams:</td>
      <td className='col-8'>
        <select className='form-select' onChange={(e) => props.onChangeHandler(provideTeamInfo(e.target.value))} >
          <option></option>
          <option value={teams.homeTeamName} id='homeTeam'>{teams.homeTeamName}</option>
          <option value={teams.awayTeamName} id='awayTeam'>{teams.awayTeamName}</option>
        </select>
      </td>
    </tr>
  )
}
