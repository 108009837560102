import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDhzIvXIqZHgGPD3rvQEKDfPtl2XxkDymg",
  authDomain: "videovolley-231118.firebaseapp.com",
}

firebase.initializeApp(firebaseConfig);

//const provider = new firebase.auth.GoogleAuthProvider();

export const auth = firebase.auth();
//export const firestore = new firebase()

export const getUserToken = () => {
  if(firebase.auth().currentUser !== null){
    console.log(firebase.auth().currentUser.toJSON().stsTokenManager.accessToken)
    return firebase.auth().currentUser.toJSON().stsTokenManager.accessToken
  }
  else {
    return undefined
  }
}

export const sendResetPWEmail = (subjectEmail) => auth.sendPasswordResetEmail(subjectEmail)

export const signOut = () => firebase.auth().signOut()
