import React from 'react'

export default function ActionSelector({...props}){
  const actions = [{name: '', id: ''},{name: 'Serve', id: 'S'}, {name: 'Reception', id: 'R'}, {name: 'Set', id: 'E'}, {name: 'Attack', id: 'A'}, {name: 'Block', id: 'B'}, {name: 'Freeball', id: 'F'}, {name: 'All', id: '.'}]
  const actionList = actions.map((action, id) => {
    return <option key={`${id}_${action.name}`}>{action.name}</option>
  })

  return (
    <tr>
      <td className='col-4'>Action:</td>
      <td className='col-8'>
        <select className='form-select' onChange={(e) => props.onChangeHandler(actions.find(action => action.name === e.target.value))}>
          {actionList}
        </select>
      </td>
    </tr>
  )
}
