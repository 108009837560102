import React from 'react'
import { Link, useLocation } from 'react-router-dom' 
import { connect } from 'react-redux'
import Banner from './Banner'
import { Reset_User } from '../actions/user.actions'

const Header = (props) => {
  let URLatGame = useLocation().pathname.split('/')
  
  return (
    <>
      <nav class="navbar navbar-dark bg-dark navbar-expand-sm">
        <ul className='navbar-nav mr-auto'>
          {props.user.email ?
            <li className='nav-item p-1' >
              <Link to='/' className='navbar-brand'>Videovolley</Link>
            </li>
            :
            <li className='nav-item p-1' >
              <Link to='/signin' className='navbar-brand'>Videovolley</Link>
            </li>
          }
          {props.user.email ?
            <li className='nav-item'>
              <Link to='/' className='nav-link'>Clubs</Link>
            </li>
            :
            <li className='nav-item'>
              <Link to='/signin' className='nav-link'>Signin</Link>
            </li>
          }
          {URLatGame[3] !== undefined ?
            <li className='nav-item'>
              <Link to={'/c/' + URLatGame[2]} className='nav-link'>Games</Link>
            </li>
            : null}

        </ul>
        {props.user.admin || props.user.manager ?
          <>
            {


              <ul className='navbar-nav mr-auto'>
                <li className='nav-item p-1'>
                  {props.user.admin ?
                    <Link to='/admin' className='nav-link'>Admin</Link> : null
                  }
                </li>
                <li className='nav-item p-1'>
                  {props.user.manager ?
                    <Link to='/manage' className='nav-link'>Manage</Link> : null
                  }
                </li>
              </ul>

            }
          </>
          : null
        }
      </nav>
      <Banner />
    </>

  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  Reset_User: () => dispatch(Reset_User())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
