import { SET_GAMES, STARTED_LOADING_GAMES } from "../actions/games.actions"

const initialState = {
  games: [],
  isLoading: undefined,
  noNeedToFetchAgain: undefined,
  clubName: undefined
}

export const gamesReducer = (state = initialState, action) => {
  switch (action.type) {
  
    case 'INSERT_ACTION_HERE':
    return {
      ...state
    }
  case SET_GAMES:
    let temp = action.payload.games

    return {
      ...state,
      games: temp,
      isLoading: false,
      noNeedToFetchAgain: true,
      clubName: action.payload.clubName
    }
  case STARTED_LOADING_GAMES: 
    return {
      ...state,
      isLoading: true,
      games: []
    }
  default:
    return state
  }
}
