import React from 'react'

export default function PlayerSelector({players = [], ...props}) {

  const playerList = players.map((player, id) => {
    return <option key={`${id}_${player.playerNumber}`} value={player.playerNumber + '. ' + player.lastName} >{player.playerNumber + '. ' + player.lastName}</option>
  })

  return (
    <tr>
      <td>Players:</td>
      <td>
        <select className='form-select' onChange={(e) => props.onChangeHandler(players.find(player => e.target.value === `${player.playerNumber}. ${player.lastName}`))}>
          <option key={'default_playerlist_value'}></option>
          {playerList}
        </select>
      </td>
    </tr>
  )
}