import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux'
import { Update_Club_Property } from '../../actions/clubs.actions';
import { Show_Banner } from '../../actions/banner.actions';

export const UploadClubImage = (props) => {
  const { showing, club } = props
  const { token } = props.user

  const [files, setFiles] = useState([])
  const handleClose = () => {
    props.close()
  }

  const onClickHandler = async () => {
    if(files.length > 0) {
      let formData = new FormData()

      //Using in rather than of since i'd like an id for the append-object
      for(let file in files){
        formData.append(`file_${file}`, files[file])
      }    

      await fetch(`/api/v1/clubs/upload/clubImage/${club.documentRef}`, {
        method: 'POST',
        headers: {'Authorization': `Bearer ${token}`},
        body: formData
      })
      .then(res => res.json())
      .then(res => res.status ? props.UCP(club.documentRef, {imgLink: res.fileURL}) : null)
      .catch(err => props.Show_Banner(err.Message, 'danger'))
    }
    setFiles([])
    handleClose()
  }

  return (
    <>
      <Modal show={showing} onHide={handleClose}>
        <Modal.Body>
          <table className='mx-auto'>
            <tbody>
              <input type='file' accept='image/png' onChange={(e) => setFiles(e.target.files)} />
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer className='justify-content-evenly'>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={onClickHandler}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  UCP: (club, data) => dispatch(Update_Club_Property({club, data})),
  Show_Banner: (msg, severity) => dispatch(Show_Banner(msg, severity))
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadClubImage)

