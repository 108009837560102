import { HIDE_BANNER, SHOW_BANNER } from "../actions/banner.actions"

const initialState = {
  active: false, 
  msg: 'Something went wrong :( please reload the page',
  class: 'alert-danger',
  link: undefined
}

let severityClass = {
  primary:    "alert-primary",
  secondary:  "alert-secondary",
  success:    "alert-success",
  danger:     "alert-danger",
  warning:    "alert-warning",
  info:       "alert-info",
  light:      "alert-light",
  dark:       "alert-dark",
}

export const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INSERT_ACTION_HERE':
      return {
        ...state
      }
    case SHOW_BANNER:
      return {
        ...state,
        active: true,
        msg: action.payload.msg,
        class: severityClass[action.payload.severity],
        link: action.payload.link
      }
    case HIDE_BANNER:
      return {
        ...state,
        active: false
      }
    default:
      return state
  }
}
