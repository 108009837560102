import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux'

export const AddBatchUsers = (props) => {
  const { showing } = props
  const handleClose = () => props.close()
  const handleShow  = () => props.show()

  return (
    <>
      <Button variant="primary" className='my-2 mx-2' disabled onClick={handleShow}>
        Batch Add Users
      </Button>

      <Modal show={showing} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Batch Add Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Woohoo, you're reading this text in a modal!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleClose}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBatchUsers)
