import React, { useState } from 'react'
import { connect } from 'react-redux'
import UploadClubImage from './UploadClubImage'

export const ClubInfo = (props) => {
  const [visibility, setVisibility] = useState(true)
  const [showingUploadModal, setShowingModal] = useState(false)
  const { club } = props

  const handleVisibility = () => {
    setVisibility(!visibility)
  }

  const onClickHandler = ({type, data}) => {
    switch(type){
      case 'ChangeClubImage': {
        setShowingModal(true)
        break
      }
      default: {
        break;
      }
    }
  }

  if(visibility)
    return (
      <>
        {showingUploadModal ? <UploadClubImage close={() => setShowingModal(false)} showing={showingUploadModal} club={club} /> : null}
        <div className='col-12 my-1'>
          <div className='d-flex flex-row justify-content-between' onClick={handleVisibility}>
            <h4>Info about Team / Club</h4>

            <i className="bi bi-arrows-collapse" />
          </div>
          <div className='row'>
            <div className='col-6'>
              <p>Identifier: {club.identifier}</p>
              <p>Name: {club.name}</p>
              <p>Created By: {club.createdBy.name} - <small><a href={`mailto:${club.createdBy.email}`}>{club.createdBy.email}</a></small></p>
              <p>Club Image: {club.imgLink}</p>
              <p>Last Upload: {new Date(club.lastUpload._seconds * 1000).toLocaleString('sv-SE')}</p>
              <p>Created on: {new Date(club.createdOn._seconds * 1000).toLocaleString('sv-SE')}</p>
            </div>
            <div className='col-6 text-center align-middle'>
              {club.imgLink ? <img className='shadow-lg rounded-circle my-3' src={club.imgLink} width={150} height={150} alt={`Club ${club.name}`} /> : <p>Click the button below to add an image. (150px x 150px)</p>}
              <p><button className='btn btn-sm btn-outline-secondary' onClick={() => onClickHandler({type: 'ChangeClubImage'})}><i className="bi bi-pencil-square" /></button></p>
            </div>
          </div>
          <hr />
        </div>
      </>
    )
  else 
    return (
      <div className='my-1' onClick={handleVisibility}>
          <div className='d-flex flex-row justify-content-between' onClick={handleVisibility}>
          <h4>Info about Team / Club</h4>
          <i className="bi bi-arrows-expand" />
        </div>
        <hr />
      </div>
    )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = dispatch => ({
  
})

export default connect(mapStateToProps, mapDispatchToProps)(ClubInfo)
