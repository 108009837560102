
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Show_Banner } from '../../actions/banner.actions'


export const Users = (props) => {
  const { club, Show_Banner} = props
  const { token } = props.user

  const [userArray, setUserArray] = useState([])
  const [visibility, setVisibility] = useState(false)
  const [email, setEmail] = useState('')

  const handleVisibility = () => {
    setVisibility(!visibility)
  }

  useEffect(() => {
    if(visibility){    
      fetch(`/api/v1/users/get-users/${club.documentRef}`, {
        headers: {'Authorization': `Bearer ${token}`}
      })
      .then(res => res.json())
      .then(res => res.status ? setUserArray(res.users) : null)
      .catch(err => Show_Banner('Could not reach server. Please reload and typ again', 'danger'))
    }
    return () => {
      setUserArray([])
    }
  }, [club, Show_Banner, visibility, token])
  /*
  const changePropertiesInUserArray = ({user, properties}) => {
    let _temp = [...userArray]
    let index = _temp.findIndex(u => u.uid === user.uid)
    _temp[index] = {..._temp[index], ...properties}

    setUserArray(_temp)
  }
  */

  const onClickHandler = ({type, data}) => {
    switch (type){
      case 'DeleteUserFromClub': {
        fetch('/api/v1/users/revoke-access', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
          body: JSON.stringify({clubID: club.documentRef, uid: data.user.uid})
        })
        .then(res => res.json())
        .then(res => res.status ? setUserArray(userArray.filter(user => user.uid !== data.user.uid)) : Show_Banner(`User ${data.user.name} was not removed`, 'warning'))
        .catch(err => Show_Banner('Error deleting user from club param', 'danger'))

        break;
      }
      case 'AddClubToUser': {
        setEmail('')
        if(email.length > 0){
          fetch('/api/v1/users/add-access', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            body: JSON.stringify({clubID: club.documentRef, email: data.email})
          })
          .then(res => res.json())
          .then(res => res.status ? setUserArray([...userArray, ...res.users]) : Show_Banner(`Couldn't find user with email ${data.email}`, 'warning'))
          .catch(e => console.error(e))  
        }
        else {
          Show_Banner('Email missing.', 'warning')
        }

        break;
      }
      default: {
        break;
      }
    }
  }

  const table = () => {
    return <table className='table table-sm table-bordered table-hover'>
      <thead>
        <tr>
          <td className='col-2'>Name</td>
          <td className='col-2'>Email</td>
          <td className=''>UID</td>
          <td className='col-1 text-center'>Status</td>
          <td className='text-center'>Remove</td>
        </tr>
      </thead>
      <tbody>
        {userArray.sort((a, b) => b.uid - a.uid).map(user => {
          return <tr className={user.disabled ? 'table-secondary' : ''} key={user.uid} >
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>{user.uid}</td>
            <td className='text-center'>{user.disabled ? 'Disabled' : 'Enabled'}</td>
            <td className='text-center'><button className='btn btn-danger btn-sm' onClick={() => onClickHandler({type: 'DeleteUserFromClub', data: {user}})}>Remove</button></td>
          </tr>
        })}
      </tbody>
    </table>
  }

  if(visibility)
  return (
    <>
      <div className='col-12 my-1'>
        <div className='d-flex flex-row justify-content-between' onClick={handleVisibility}>
        <h4>Users <small className="text-muted">with access</small></h4>
          <i className="bi bi-arrows-collapse" />  
        </div>
        <div className='row'>
          <div className='col-12'>
            <input type='text' className='col-6' onChange={(e) => setEmail(e.target.value)} placeholder={'Add users by adding emails, separate them with a comma'} value={email} />
            <button className='m-2 btn btn-success btn-sm' onClick={() => onClickHandler({type: 'AddClubToUser', data: {email, club}})}>Add</button>
          </div>
        </div>
        {table()}
        <hr />
      </div>
    </>
  )
else 
  return (
    <div className='col-12 my-1' onClick={handleVisibility}>
      <div className='d-flex flex-row justify-content-between' onClick={handleVisibility}>
        <h4>Users <small className="text-muted">with access</small></h4>
        <i className="bi bi-arrows-expand" />
      </div>
    <hr />
  </div>
)
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  Show_Banner: (msg, severity) => dispatch(Show_Banner(msg, severity))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
