import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

export const ClubCards = (props) => {
  const club = props.club

  return (
    <div className='card text-center border-dark col-sm-12 col-md-2 m-2' style={{ padding: '0px' }} >
      <Link to={'c/' + club.identifier} >
        <div className="card-header">
          {club.name || club.identifier}
        </div>
        <div className='card-body justify-content-center' >
          {club.imgLink ? <img src={club.imgLink} alt={club.name} width='120px'></img> : null}
        </div>
      </Link>
    </div>
  )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ClubCards)
 